$bubble-background: transparentize(black, 0.3);

.message-bubble {
  position: absolute;
  background-color: $bubble-background;
  padding: 10px;
  min-width: 160px;
  max-width: 300px;
  border-radius: 5px;
  bottom: calc(100% + 15px);
  left: -20px;

  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    width: 0;
    height: 0;
    left: 22px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 15px solid $bubble-background;
  }
}
