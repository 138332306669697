.flag-widget {
  .header-wrapper {
    width: 100%;
    border-bottom: 2px solid;
    padding: 5px 0;
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
    gap: 8px;

    &.needs_translation {
      padding-bottom: 0;
    }

    &.comments {
      border-color: $color-comments;

      i {
        color: $color-comments;
      }
    }

    .heading {
      flex: 1;
    }

    .language-tab {
      padding: 2px 8px 3px 8px;
      border-radius: 3px 3px 0 0;
      margin-left: 5px;
      font: inherit;

      &:hover {
        background-color: transparentize($color-border-grey, 0.3);
      }

      &.active {
        color: white;
        background-color: $color-needs-translation;
      }
    }
  }

  .table-entry {
    display: flex;
    flex-direction: row;
    color: $color-primary-text;

    &.active {
      color: $color-text-medium-grey;
    }

    &.selected {
      color: $color-primary-text;
    }

    .label {
      flex: 1 1 auto;
      font-weight: bold;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .element-count {
      display: inline-block;
      white-space: nowrap;

      i {
        margin-left: 5px;
      }

      .percent {
        margin-left: 5px;
        color: $color-text-medium-grey;
      }
    }
  }
}