.overlay .revision-history-overlay.overlay-wrapper.full-height {
  width: 45vw;
  left: 35vw;
}

.revision-history__header {
  display: flex;
}

.revision-history-header__icon {
  color: $color-primary;
  margin-right: 10px;
  font-weight: normal;
}

.revision-history-header__language-switcher {
  grid-column-start: 4;
  grid-column-end: 6;
  -ms-grid-column: 4;
  -ms-grid-column-span: 3;
}

.revision__item {
  margin: 0 0 0 20px;
  padding: 10px 20px;
  border-left: 3px solid lighten($color-primary, 5);
}

.revision-block:last-of-type .revision__item:last-of-type {
  border-color: transparent;
  border-image: linear-gradient(
      lighten($color-primary, 5) 20px,
      transparent 1px
    )
    1;
}

.revision-block:first-of-type .revision__item:first-of-type {
  border-color: transparent;
  border-image: linear-gradient(
      to top,
      lighten($color-primary, 5) 20px,
      transparent 1px
    )
    1;
}

.revision-item--non-consecutive {
  border-style: dotted;
}

.revision-block__header {
  display: flex;
}

.revision-block__header-date {
  background-color: black;
  padding: 5px 10px;
  border-radius: 10px;
  color: $color-text-light-grey;
  font-weight: bold;
}

.revision-item__header {
  display: flex;
  justify-content: space-between;
  color: $color-text-medium-grey;
  position: relative;
  overflow: visible;
}

.revision-item-header__dot {
  border-radius: 50%;
  border: 2px solid white;
  background-color: $color-text-light-grey;
  position: absolute;
  top: 1px;
  left: -27px;
  height: 7px;
  width: 7px;
}

.revision-item-header__description {
  flex: 1;
  display: flex;
  margin: 0 -5px;

  & > div {
    margin: 0 5px;
  }
}

.revision-item-header__time,
.revision-item-header__title {
  font-weight: bold;
}

.revision-item-header__revert-button {
  display: none;
  color: $color-primary;
}

.revision-item--hovered {
  .revision-item-header__revert-button {
    padding: 0;
    font: inherit;
    display: flex;
  }

  .revision-item__content {
    border-left: 3px solid lighten($color-primary, 5);
  }
}

.revision-item__content {
  margin-left: 40px;
  margin-top: 5px;
  padding: 10px;
  background-color: white;
  border-radius: 3px;
  border-left: 3px solid transparent;
}

.revision-item__content-box {
  display: flex;
  flex-wrap: wrap;
}

.country-diff__sub-header {
  display: flex;
  font-weight: bold;
  margin: 5px 0;

  .langtag-label {
    margin: 0 5px;
  }
}

.country-diff__group {
  display: flex;

  .content-diff {
    margin-right: 5px;
  }
}

.country-diff-group {
  padding: 5px;

  &:nth-child(even) {
    background-color: transparentize($color-text-light-grey, 0.7);
  }
}

@mixin link-diff-color($basecolor) {
  background-color: $basecolor;

  &.content-diff--foreign-row-deleted {
    background-color: transparentize($basecolor, 0.3);
  }
}

.link-diff {
  margin: 2px;
  padding: 8px;
  border-radius: 3px;
  color: $color-primary-contrast-text;
  @include link-diff-color($color-primary);
  display: flex;

  &.content-diff--added {
    @include link-diff-color($color-revision-link-added);
  }

  &.content-diff--deleted {
    @include link-diff-color($color-revision-deleted);
  }

  .link-diff__icon {
    margin-right: 5px;
    position: relative;

    svg {
      fill: $color-primary-contrast-text;
    }
  }
}

.content-diff--added {
  background-color: $color-revision-added;
}

.content-diff--deleted {
  background-color: $color-revision-deleted;
  text-decoration: line-through;
}

.annotation_removed {
  text-decoration: line-through;
  text-decoration-color: $color-text-medium-grey;
  border: none;
}

.diff-comment-item {
  display: flex;
}

.diff-comment-item__icon {
  margin-right: 10px;
}

.diff-comment-item.annotation_added {
  .diff-comment-item__icon {
    color: $color-revision-link-added;
  }
}

.diff-comment-item.annotation_removed {
  color: $color-text-medium-grey;

  .diff-comment-item__comment-value {
    text-decoration: line-through;
    text-decoration-color: $color-text-medium-grey;
  }
}

.history-filter-area__context {
  font-size: 14px;
  color: $color-text-medium-grey;
}

.history-filter-area__title {
  font-size: 20px;
  margin-bottom: 20px;
}

.revision-history-overlay .overlay-content .history-overlay__body {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.revision-history-overlay .overlay-content {
  overflow: hidden;
}

.history-overlay__content {
  overflow-y: auto;
}

.history-filter-area {
  background-color: $color-revision-filter-background;
  color: white;
  padding: 15px 50px;
  margin: 0;
}

// The picker sets the same color to most elements inside the filter area
.history-filter-area .rdtPicker {
  color: black;
  .rdtDay:hover {
    background-color: $color-primary;
    color: $color-primary-contrast-text;
  }
}

.history-popup__header {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.history-filter__toggle-filters-button,
.history-filter__clear-filters-button {
  @include button-look();
  display: flex;

  .fa {
    margin-right: 5px;
  }
}

.toggle-filter-button__arrow {
  margin-left: 5px;
}

.history-filter__clear-filters-button {
  background-color: transparent;
  color: $color-text-medium-grey;
  display: none;
  border-radius: 3px 3px 0 0;

  &.clear-filters-button--has-filters {
    display: flex;
  }

  &:hover {
    background-color: $color-text-light-grey;
    color: $color-primary-contrast-text;
  }
}

.history-filter__toggle-filters-button {
  background-color: $color-text-light-grey;
  color: $color-primary-text;

  &.toggle-filters-button--open {
    border-radius: 3px 3px 0 0;
  }

  &.toggle-filters-button--has-filters,
  &:hover {
    background-color: $color-primary;
    color: $color-primary-contrast-text;
  }
}

.history-popup__body {
  border-top: 2px solid $color-text-light-grey;
  background-color: #2d3135;
  padding: 10px;
  padding-bottom: 0;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 1fr;

  &.popup-body--has-filters {
    border-color: $color-primary;
  }
}

.search-bar {
  position: relative;
}

.search-bar__input {
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 3px;
  border: none;
  width: 100%;
}

.search-bar__icon {
  position: absolute;
  right: 0;
  top: 0;
  color: $color-text-medium-grey;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.history-popup-item__header {
  text-transform: uppercase;
  padding-bottom: 8px;
  color: $color-text-medium-grey;
  font-weight: bold;
}

.history-popup__item {
  margin: 0 5px;
  margin-bottom: 10px;
  padding-bottom: 3px;

  &.item__select-from,
  &.item__select-to,
  &.item__select-author {
    border-bottom: 1px solid $color-primary;

    .search-bar__input {
      color: $color-text-light-grey;
      border-bottom: none !important;
    }
  }

  &.item--large {
    grid-column-start: 1;
    grid-column-end: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  &.item__select-author {
    .search-bar__input {
      padding: 0;
      background-color: transparent;
    }
  }
}

.history-date-picker {
  display: flex;
  width: 100%;
  position: relative;
}

.history-date-picker .rdt {
  position: absolute;
  left: 0;
  top: 28px;
}

.history-date-picker__picker-button {
  flex: 1;
  display: flex;
  margin: 3px 5px;
}

.history-date-picker__clear-button {
  margin: 3px 5px;
}

.history-date-picker__button-icon {
  color: $color-primary;
}

.history-date-picker__date {
  margin-left: 5px;
  color: $color-text-light-grey;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-control {
  display: none;
}

.confirm-revert {
  display: grid;
  display: -ms-grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 1fr;
  text-align: center;
}

.confirm-revert__preview {
  width: 100%;
  box-shadow: -7px 0px 15px $color-text-medium-grey;
}

.confirm-revert__preview .revision-item__content {
  padding: 20px;
  margin: 20px;
}

.revisin-item__content-box {
  overflow: hidden;
  overflow-x: auto;
}

.confirm-revert-preview__header {
  margin: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: transparentize($color-very-dark, 0.3);
}

.confirm-revert-preview__date-string {
  color: $color-text-medium-grey;
  text-align: left;
  display: flex;
  margin: 20px;
}

.confirm-revert-preview__date {
  margin: 0 5px;
}

.overlay .revision-history-overlay .confirm-revert {
  padding: 0;
  margin: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // ------ IE needs every grid item to be placed manually

  .item__select-from {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
  }

  .item__select-to {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }

  .item__select-annotations {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }

  .item__select-comments {
    -ms-grid-column: 2;
    -ms-grid-row: 3;
  }

  .confirm-revert-preview__left {
    -ms-grid-column: 1;
  }

  .confirm-revert-preview__right {
    -ms-grid-column: 2;
  }

  // ----- and IE places stuff wrong

  .overlay
    .revision-history-overlay.overlay-wrapper.full-height.is-right:not(.is-left) {
    transition: none;
    left: 55vw;
  }
}
