.context-menu {
  position: absolute;
  z-index: 2;
  max-height: none;

  background-color: $color-overlay-header;
  border-radius: 4px;
  padding: 20px 0;

  &.light {
    background-color: $color-white;

    .item-label {
      color: $color-primary-text;
    }

    button, a {
      &:hover,
      &.active {
        background-color: $color-hover-background;
        color: $color-hover-foreground;

        .item-label {
          color: $color-hover-foreground;
        }
      }
    }
  }

  button,
  a {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding: 10px 25px;
    align-items: center;

    i, .svg-icon {
      width: 13px;
      margin-right: 7px;
      padding: 0;
      color: $color-text-medium-grey;
      fill: $color-text-medium-grey;
      position: relative;
    }

    .item-dot {
      margin-right: 10px;
    }

    .item-label ~ i {
      position: absolute;
      right: 0;
      margin-right: 10px;
    }

    .item-label {
      color: $color-primary-contrast-text;
    }

    &:hover {
      background-color: $color-hover-dark;
    }
  }

  .separator {
    padding: 10px 25px 10px 45px;
    width: 100%;
    color: $color-text-medium-grey;
    text-transform: uppercase;
    box-sizing: border-box;
    font-weight: bold;

    &.with-line {
      border-top: 1px solid transparentize($color-text-medium-grey, 0.5);
      padding-top: 25px;
      margin-top: 15px;
    }

    &--internal {
      padding: 0;
      border-top: 1px solid transparentize($color-text-medium-grey, 0.5);
      margin: 8px 25px;
      box-sizing: border-box;
    }
  }
}
.prevent-scroll {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.column-head .context-menu {
  @include context-menu-look();
  position: absolute;
  font-weight: normal;
  right: 0;
  top: 40px;
  z-index: 2;

  button {
    border: none;
    display: block;
    padding: 10px 10px;

    &:hover {
      background-color: $color-select-list-selected;
    }
  }
}

.annotation-context-menu {
  display: none;
  padding: 10px 0;
  width: 250px;
  position: absolute;
  top: -10px;
  left: 100%;
}

.annotation-context-menu-button {
  position: relative;
  cursor: pointer;

  &:hover {
    .annotation-context-menu {
      display: block;
    }
  }
}
