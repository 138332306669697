@import "variables";

.richtext-cell-editor .rich-text-wrapper {
  height: calc(100vw - 321px);
  .rich-text-component .content-pane {
    height: 100%;
    max-height: none;
  }
}

.rich-text-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  .rich-text-component {
    flex: 1;
    width: 100%;
    min-height: 30px;
    background-color: transparent;
    box-sizing: border-box;
    padding-bottom: 4px;
    border-bottom: 1px solid transparent;
    margin-bottom: 5px;
    transition: border 0.3s;

    &:hover, &:focus {
      border-bottom: 1px solid $color-primary;
    }

    .symbol-bar {
      width: 100%;
      padding: 10px 0;
      margin-bottom: 10px;
      border-bottom: 1px solid $color-text-light-grey;

      .action-group {
        margin-right: 1em;
        display: inline-flex;
        align-items: baseline;
        vertical-align: bottom;
        color: $color-text-medium-grey;

        .description {
          padding-right: 10px;
        }
      }

      a {
        padding: 4px;
        background-color: $color-text-light-grey;
        color: $color-border-grey;
        margin-right: 5px;
        text-align: center;
        border-radius: 3px;

        i {
          width: 1em;
        }

        &:hover {
          background-color: $color-primary;
          color: $color-primary-contrast-text;
        }
      }
    }

    .button-area {
      text-align: right;
    }

    .content-pane {
      width: 100%;
      max-height: 70vh;
      overflow: hidden;
      line-height: 1.5em;
    }
  }
}

.content-pane.input, .content-box { // the latter is for react-markdown components
  overflow-y: auto;
  color: $color-primary-text;
  background-color: transparent;

  i, em {
    font-style: italic;
  }

  b, strong {
    font-weight: bold;
  }

  ul > li {
    list-style: circle inside;
  }

  ol > li {
    list-style: decimal inside;
  }

  p {
    margin: 5px;
  }

  h1, h2, h3, h4 {
    background-color: transparent;
    text-align: left;
    border: none;
    margin: 5px 0;
    font-weight: bold;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.5rem;
  }
}
