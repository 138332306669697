//Global styling overrides for the Select box
.Select {

  &.is-focused:not(.is-open) {
    .Select-control {
      box-shadow: none;
      border-color: #91c8ff;
    }
  }

  &.is-searchable {
    .Select-input {
      position: relative;
      overflow: hidden;

      /* Fixes IE's ugly quake effect when typing */
      display: block !important;
      input, div {
        width: 100% !important;
      }
      /* End of IE fix */
    }

    &.is-open {
      .Select-value {
        opacity: 0.5;
      }
    }
  }

  .Select-option {
    &.is-focused {
      background-color: $color-select-list-selected;
    }

    &.is-selected {
      background-color: $color-select-list-focus;
    }
  }

  .Select-control:hover {
    box-shadow: none;
  }

  .Select-noresults {
    line-height: 1.3em;
  }

  .Select-placeholder {
    line-height: 29px;
  }

}

//open react-select submenu on top when class is set
.open-on-top {
  .Select-menu-outer {
    top: auto;
    bottom: 100%;
  }
}