#tableaux {
  font-size: $font-size-standard;
  background-color: $color-body-background;

  .wrapper {
    @include box-sizing(border-box);
    padding-top: $space-height-header;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: $color-body-background;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.resize-bar {
  width: 2px;
  height: 100%;
  background-color: $color-primary;
  position: absolute;
  z-index: 2;
}

.resize-bar-invisible {
  width: 0px;
  height: 0px;
}

#tableHeader {
  overflow: visible;
}

#table-wrapper {
  display: block;
  line-height: 1.2em;
  width: 100%;
  height: 100%;
  position: relative;

  .ReactVirtualized__Grid__innerScrollContainer {
    display: table;
  }
}

#virtual-table-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

body div div.column-header-context-menu {
  @include context-menu-look();
  background-color: $color-primary-contrast-text;
  :hover {
    background-color: $color-hover-background;
    color: $color-hover-foreground;
  }
  i {
    margin-left: 10px;
  }
  padding: 0;
}

.column-context-menu__item {
  padding: 8px 15px;
  display: flex;
  cursor: pointer;

  .column-context-menu-item__icon {
    width: 20px;
    color: $color-text-medium-grey;

    &:nth-of-type(2) {
      text-align: center;
    }
  }
}

.tableaux-table {
  &--settings,
  &--generic {
    background-color: $color-white;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;

  .heading {
    @include box-sizing(border-box);

    position: relative;
    min-width: 100%;
    left: 0px;
    top: 0px;
    z-index: 2;
    overflow: hidden;
    height: $space-height-table-header;

    &.sticky {
      position: fixed;
      z-index: 9;
      top: 0;
      left: 50px;
    }

    .tableHeader-inner {
      height: $space-inner-height-table-header;
    }
  }

  .column-head,
  .meta-cell-head {
    border-right: 1px solid $color-border-grey;
    overflow: visible;
    white-space: nowrap;
    position: relative;
    height: $space-inner-height-table-header !important;
    border-bottom: $space-border-size-table-header solid $color-border-grey;
    font-weight: bold;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .drag-handle {
      width: 10px;
      height: 100%;
      background-color: red;
      cursor: ew-resize;
    }

    .column-name-wrapper {
      width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;

      .tableHeader-inner {
        padding: 4px 8px;
        border: 1px solid transparentize($color-text-light-grey, 0.5);
        border-radius: 3px;
        i {
          color: $color-text-light-grey;
        }
      }

      .description-hint {
        color: $color-text-light-grey;
        margin-left: 6px;
      }
    }

    .column-link-wrapper {
      overflow: visible;

      a {
        max-width: 90%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .column-contextmenu-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 10%;
      min-width: 20px;
      line-height: $space-inner-height-table-header;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .column-table-link {
      border: 1px solid #dfdfdf;
      border-radius: 3px;
      padding: 3px 10px;
      vertical-align: middle;

      i {
        margin-right: 5px;
        padding: 0;
        color: $color-text-light-grey;
      }
    }

    i {
      margin-right: 10px;
      vertical-align: middle;
    }

    .column-kind-icon {
      i {
        margin-right: 5px;
      }
      position: absolute;
      right: 35px;
      color: $color-text-medium-grey;
      .label {
        font-size: 10px;
        vertical-align: top;
      }
    }
  }

  .column-selected {
    border: 1px solid $color-primary;
    background: $color-selected-row;
  }

  .heading,
  .row {
    display: table;
    table-layout: fixed;

    .column-head,
    .cell {
      display: table-cell;
      width: $space-standard-cell-width;
      min-width: $space-standard-cell-width;
      max-width: $space-standard-cell-width;
      padding: 9px;
      @include box-sizing(border-box);

      &.meta-cell {
        width: 80px;
        min-width: 80px;
        max-width: 80px;
      }

      &.repeat {
        color: $color-text-medium-grey;
        font-size: 1.2em;
        text-align: center;
        padding: 0px;
      }
    }
  }

  //scroll to left start button

  $scrollToLeftButtonSize: 30px;

  #scrollToLeftStart {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: $scrollToLeftButtonSize;
    height: $scrollToLeftButtonSize;
    line-height: $scrollToLeftButtonSize;
    background-color: #ffffff;
    color: $color-dark;
    @include border-radius(3px);
    border: 1px solid $color-grey-dark;
    text-align: center;
    z-index: 2;

    &:hover {
      cursor: pointer;
      background-color: $color-grey-dark;
    }

    &.hide {
      display: none;
    }
  }
}

.table-has-no-rows {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 108px;
  text-align: center;
  font-weight: bold;
  z-index: 1;
}

#cell-jump-toast {
  padding: 25px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
}

.Select {
  .Select-option {
    &.is-disabled {
      background-color: $color-background-disabled;
      color: $color-foreground-disabled;
    }
  }
}

.description-tooltip {
  position: absolute;
  z-index: 50;
  max-width: 250px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  background-color: transparentize($color-primary-text, 0.1);
  color: $color-text-light-grey;

  .description-tooltip-text {
    white-space: normal;
    font-weight: normal;
  }
  &:before {
    content: "";
    position: absolute;
    left: 7%;
    top: -15px;
    border-left: 5px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 15px solid transparentize($color-primary-text, 0.1);
  }

  &.shift-left {
    transform: translateX(-90%);

    &:before {
      left: auto;
      right: 4%;
      border-left: 8px solid transparent;
      border-right: 5px solid transparent;
    }
  }
}

.ReactVirtualized__Grid {
  &.multigrid-bottom-left,
  &.multigrid-bottom-right {
    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: visible !important;
    }
  }
  &.multigrid-bottom-left {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  &.multigrid-bottom-left::-webkit-scrollbar {
    width: 0 !important;
  }
}
