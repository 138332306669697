.greeter {
  font-size: 32px;
  font-weight: bold;

  .info-text {
    font-size: medium;
    font-weight: normal;
  }

  .user-name {
    margin-left: 6px;
  }
}