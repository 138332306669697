html {
  font-size: $font-size-base;
}

body {
  font-size: $font-size-standard;
  color: $color-primary-text;
}

html, body, input {
  font-family: $font-main;
  font-weight: normal;
}


h1, h2, h3, h4, h5, h6 {
  font-family: $font-sec;
}

a {
  text-decoration: none;
  color: inherit;
}
