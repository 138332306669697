.taxonomy-search.filter-bar {
  grid-column-end: 5;
}

.overlay-wrapper.link-overlay.link-overlay--taxonomy,
.overlay-wrapper.select-link-target-overlay.select-link-target-overlay--taxonomy {
  .overlay-content {
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .tree-node__child-count {
    display: none;
  }

  .overlay-main-content {
    overflow-y: auto;
    margin-bottom: 0;
    padding: 0 24px;
  }

  .tree {
    max-width: none;
  }

  .overlay-subheader {
    font-size: 13px;

    &__title {
      font-size: 16px;
      padding-bottom: 8px;
      font-weight: 600;
    }

    .cardinality-count {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 8px;
      span:not(:first-child) {
        margin-left: 4px;
      }
      .number {
        color: $color-very-dark;
      }
    }

    .taxonomy-link-overlay__linked-items {
      margin-top: 16px;
    }

    .linked-item {
      display: flex;
      height: var(--tree-item-height);
      &:not(:last-of-type) {
        margin-bottom: var(--tree-item-margin-y);
      }
      &__content {
        flex: 1;
        background-color: $color-primary;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        white-space: nowrap;
        max-width: calc(
          100% - var(--tree-item-height) - var(--tree-item-margin-y)
        );
        box-sizing: border-box;

        &-title {
          color: $color-primary-contrast-text;
          font-weight: bold;
        }
      }

      .set-link-button {
        min-width: var(--tree-item-height);
        max-width: var(--tree-item-height);
        margin-right: 8px;

        i {
          padding-top: 4px;
        }
      }

      &__path {
        color: transparentize($color-primary-contrast-text, 0.3);
        flex: 0 1 auto;
        min-width: 0;
        display: flex;
        &-separator {
          margin: 0 4px;
        }
        &-step {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            min-width: fit-content;
          }
          &:last-of-type {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .set-link-button {
    flex: 1;
    height: 100%;
    text-align: center;
    border-radius: 4px;
    background-color: $color-light-blue;
    color: $color-primary;

    &:hover {
      background-color: darken($color-hover-background, 10);
    }

    &--disabled,
    &--disabled:hover {
      background-color: $color-text-light-grey;
      color: $color-text-medium-grey;
    }
  }
}
