.tableaux-table {

  .row {
    border-bottom: 1px solid $color-border-grey;

    &.selected {
      background-color: $color-selected-row;
    }

    &.duplicated {
      background-color: #FFF8ED;
    }

    &.final {
    }

    &:focus, &:active {
      outline-style: none;
    }

    .access-denied-icon {
      position: absolute;
      margin-top: 15px;
      margin-left: 10px;
      color:$color-button-grey;
    }
  }

  .row-group.expanded {
    background-color: #f9f9f9;
    display: table;
  }



  .new-row {
    padding: 8px 15px;
    background-color: white; //overlays the background grid image

    .new-row-inner {
      @include button-look();
    }

    i, span {
      vertical-align: middle;
    }

    i {
      margin-right: 10px;
      font-size: 16px;

    }

  }

}

