html, body {
  height: 100%;
  width: 100%;
}

body {
  background-color: $color-body-background;
}

body * {
  outline: none !important;
  outline-style: none !important;
}

a, button {
  &:active {
    outline: none !important;
    outline-style: none !important;
  }
}

header {
  @include user-select(none);
}

.initial-loader {
  background-color: $color-background-very-light-grey;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  .centered-user-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: x-large;
    color: $color-text-medium-grey;
  }
}

.clearfix {
  clear: both;
}

input, textarea {
  font-family: $font-main;
  font-size: $font-size-standard;

  &::-ms-clear {
    display: none;
  }
}

button {
  background-color: transparent;
  border: none;
  padding: none;
  margin: none;
  font-size: 1.3rem;
  font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: left;
  cursor: pointer;
}

.empty-item, .permission-denied-item {
  text-transform: uppercase;
  font-style: italic;
  font-weight: lighter;
}

button {
  background-color: transparent;
  border: none;
  padding: none;
  margin: none;
  font-size: 1.3rem;
  font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: left;
  cursor: pointer;
}

.ReactVirtualized__List {
  overflow: hidden !important;

  &:hover {
    overflow: auto !important;
  }
}

@mixin grid-row($row-number, $row-gap: 8px) {
  grid-row: $row-number;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    -ms-grid-row: $row-number;
    &:not(:first-of-type) {
      margin-top: $row-gap;
    }
    &:not(:last-of-type) {
      margin-bottom: $row-gap;
    }
  }
}

@mixin grid-column($col-number, $col-gap: 8px) {
  grid-column: $col-number;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    -ms-grid-column: $col-number;
    &:not(:first-of-type) {
      margin-left: $col-gap;
    }
    &:not(:last-of-type) {
      margin-right: $col-gap;
    }
  }
}

.row-one {
  @include grid-row(1);
}

.row-two {
  @include grid-row(2);
}

.row-three {
  @include grid-row(3);
}

.col-one {
  @include grid-column(1);
}

.col-two {
  @include grid-column(2);
}

.col-three {
  @include grid-column(3);
}

.col-four {
  @include grid-column(4);
}

.col-five {
  @include grid-column(5);
}
