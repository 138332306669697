@import "variables";

.overlay {
  .overlay-wrapper {
    .header-wrapper .Tableaux-Spinner {
      grid-column: 5;
      -ms-grid-column: 5;
      position: relative;
    }

    .header-wrapper .sort-mode-button {
      color: $color-text-medium-grey;
      background-color: $color-primary-contrast-text;
      border-radius: 3px;
      height: 31px;
      width: 31px;
      margin-left: 8px;

      grid-column: 4;
      -ms-grid-column: 4;
      grid-row: 3;
      -ms-grid-row: 3;

      &:hover {
        background-color: $color-primary;
        color: $color-primary-contrast-text;
      }
    }

    .link-overlay {
      display: flex;
      height: 100%;
      margin: 0;
      flex-direction: column;

      .linked-items {
        max-height: 30vh;
        overflow-y: auto;
        background-color: $color-white;
        border-bottom: 3px solid $color-text-light-grey;
        padding: 0px 50px 0px 25px;

        &.no-unlinked {
          max-height: none;
          flex: 1;
        }

        .link-list {
          padding: 20px 0 20px 25px;
        }
      }

      .unlinked-items {
        padding-top: 0;
        flex: 1;
      }
    }

    .row-creator-button {
      cursor: pointer;

      color: $color-primary;
      background-color: $color-white;

      height: 30px;

      position: absolute;
      left: 50%;
      bottom: 5px;
      transform: translateX(-50%);
      padding-right: 15px;

      border: 1px solid $color-primary;
      border-radius: 3px;

      display: flex;
      align-items: center;

      &.shift-up {
        top: 40vh;
        padding: 30px 30px;
      }

      div {
        padding: 0 10px;
        pointer-events: none;
      }

      &:hover {
        background-color: $color-primary;
        color: $color-primary-contrast-text;
        svg {
          fill: $color-primary-contrast-text;
        }
      }
    }
  }

  .filter-bar {
    grid-column-start: 2;
    grid-column-end: 4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-row-start: 3;
    -ms-grid-row: 3;
    position: relative;
    display: block;

    input[type="text"] {
      width: 100%;
      padding: 8px;
      border-radius: 3px;
      border: none;
      box-sizing: border-box;
    }

    button.popup-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 31px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      color: $color-text-medium-grey;
    }

    .filter-option-popup {
      position: absolute;
      right: 0;
      z-index: 1;
      background-color: $color-white;
      box-shadow: 0 5px 10px 0 $color-text-light-grey;
      .menu-item {
        position: relative;
        button {
          color: inherit;
          display: block;
          width: 100%;
          padding: 12px;
        }
        &.active {
          background-color: $color-primary;
          color: $color-primary-contrast-text;
        }
        &:hover {
          background-color: $color-hover-background;
          color: $color-hover-foreground;
        }
      }
    }
  }

  .link-overlay {
    .ReactVirtualized__List {
      padding: 15px 50px 50px 50px;
      position: absolute;
      bottom: 0;

      .ReactVirtualized__Grid__innerScrollContainer {
        position: relative;
      }
    }

    @import "./linkList.scss";
  }
}
