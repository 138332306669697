@import "helper";
@import "variables";

.table-rename-wrapper {
  input {
    height: 30px;
    line-height: 30px;
    border: 1px solid $color-grey-dark;
    @include border-radius(3px);
    outline: none;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;

    &:focus, &:active {
      border-color: $color-primary-lighter;
    }
  }
}

#table-settings-wrapper {
  display: inline-block;

  margin-top: 10px;

  .button {
    @include header-button-look();
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &.active {
      @include header-button-active();
    }
  }
}

#table-settings-popup {
  @include header-panel-look();
  padding: 0;
  width: 200px;

  .menu-item {
    width: 100%;
    padding: 10px 10px;
    cursor: pointer;
  }

  .menu-item--disabled {
    opacity: 0.5;
    cursor: default;
  }

  .menu-item:hover{
    background-color: $color-light-link-color;
  }
}
