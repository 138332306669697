@import "../variables";
@import "../helper";

.cell.cell-link,
.cell.cell-attachment {
  //Overwrite editing styling because editing mode is with overlay
  &.cell-link.editing .cell-content {
    @include box-shadow(none);
  }

  //Label
  .link-label {
    @include link-look();
    max-width: 90%;
    flex: 0 0 auto;
    display: inline-flex;
    align-items: baseline;
    text-wrap: none;
    line-height: 1em;
    margin: 0 6px 0 0;
    padding: 0;

    .label-text {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 6px 8px;
    }

    &--archived {
      background: $color-archived-bg;
      color: $color-archived-fg;
      ::before {
        content: "\f187";
        font-family: FontAwesome;
        padding-right: 8px;
      }
    }
  }
}
