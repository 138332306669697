
.multiselect-area {
  display: flex;
  flex-flow: row wrap;
  padding: 8px;
  position: relative;
  min-height: 33px;

  &.no-selection {
    justify-content: center;
    color: $color-text-medium-grey;
    &>span {
      margin-top: 10px;
    }
  }

  &:hover {
    background-color: transparentize($color-hover-background, .3);
  }

  .multiselect-drop-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .langtag {
    display: flex;
    align-items: center;
    img {
      padding-right: 5px;
    }
  }

  .multiselect-tag {
    display: flex;
    align-items: center;
    padding: 8px;
    color: $color-primary-contrast-text;
    background-color: $color-primary;
    border-radius: 3px;
    margin: 2px 4px;

    .multiselect-tag-deselect-icon {
      margin-left: 5px;
      font-size: 8px;
      svg {
        fill: $color-primary-contrast-text;
      }
    }

    &:hover {
      background-color: lighten($color-primary, 20);
      color: $color-hover-foreground;
      box-shadow: 0 0 15px $color-text-light-grey;

      svg {
        fill: $color-text-medium-grey;
      }
    }
  }

  .multiselect-list-wrapper {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 1;

    .multiselect-item-list {
      background-color: $color-primary-contrast-text;
      box-shadow: 0 0 15px $color-text-medium-grey;
    }

    .multiselect-list-item {
      padding: 5px 10px;

      &.empty-list {
        color: $color-text-medium-grey;
      }

      &:hover {
        background-color: $color-hover-background;
        color: $color-hover-foreground;
      }
    }
  }
}
