.status-cell {
  width: 100%;
  max-height: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  gap: 10px;
  align-items: center;
}
.status-icon {
  cursor: pointer;
  position: relative;
  &__block {
    cursor: auto;
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
  }
}
