@import "translationStatusWidget", "support", "greeter", "flagwidget";

#dashboard-view.wrapper {
  padding-top: 55px;
  overflow-y: auto;

  .widgets, .translation-status .content, .support .tiles {
    max-width: 1440px;
  }

  div {
    user-select: none;
  }

  .info-text {
    color: $color-text-medium-grey;
    padding-top: 10px;
  }

  .widgets-wrapper, .translation-status, .support {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .widgets-wrapper {
    background-color: white;
  }

  .widgets {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto;
    padding-bottom: 25px;

    .greeter, .needs_translation {
      grid-column: span 2;
    }

    .tile {
      padding: 35px;
      height: 200px;
      position: relative;
    }
  }

  footer {
    padding: 150px 0 50px 0;
    width: 100%;
    text-align: center;
    color: $color-text-medium-grey;
  }

}

.feedback-toast {
  border-width: 5px;
  padding: 25px;
}
