#media-wrapper {
  padding: 0px;
  top: $space-height-header + 50px;
  position: relative;
  background-color: #FFFFFF;
  margin: 0 auto;
  max-width: 1200px;
  z-index: 1;
  border: 3px solid $color-border-grey;

  .modified-file {
    background-color: $color-selected-row;
  }
}

.current-folder {
  width: inherit;
  background-color: white;
  border-top: 3px solid $color-border-grey;
  font-weight: bold;
  display: block;
  font-family: $font-sec;
  text-transform: uppercase;

  i {
    margin-right: 1em;
  }

  button {
    width: 100%;
    font: inherit;
    text-transform: inherit;
    cursor: pointer;
    text-decoration: none;
    color: $color-primary-text;
    display: block;

    &:hover {
      color: $color-primary;
    }
  }

  button, &.is-root {
    padding: 1em;
  }
}

.media-switcher {
  background-color: white;
  border-top: 3px solid $color-border-grey;
  display: block;
  padding: 1em;

  .folder-link, .file-link {
    padding: 0.75em;
    font-size: 16px;
    display: block;
  }

  //Settings container
  .media-options {
    @include transition(opacity 0.3s ease-out);
    opacity: 0.1;
    border: 1px solid $color-subtle-grey;
    margin-left: 2em;
    border-radius: 3px;
    position: absolute;
    right: 10px;
    top: 9px;
    z-index: 1;
    background-color: #FFFFFF;

    i {
      margin-right: 5px;
    }

    .button {
      display: inline-block;
      border-right: 1px solid $color-subtle-grey;
      padding: 3px 0.5em;

      &:hover {
        color: $color-primary;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  .file, .subfolder {
    &:hover .media-options {
      opacity: 1;
    }
  }

  li {

    &.active {
      background-color: $color-primary;
      color: $color-primary-contrast-text;
    }

    a {
      text-decoration: none;
      color: $color-primary-text;
      cursor: pointer;

      &:hover {
        color: $color-primary;
      }
    }
  }

  .icon {
    margin-right: 0.5em;
    width: 15px;
    text-align: center;
  }

  .new-folder-button, .create-new-folder input {
    border: 1px solid $color-subtle-grey;
    border-radius: 3px;
    display: inline-block;
    padding: 5px 10px;
  }

  .new-folder-button {
    cursor: pointer;
    &:hover {
      color: $color-primary;
    }
  }

  .create-new-folder {
    display: inline-block;
  }

  .subfolder .create-new-folder {
    padding: 0.75em;
    i {
      font-size: 16px;
    }
  }

  .file, .subfolder {
    display: block;
    position: relative;

    &:hover {
      background-color: $color-background-very-light-grey;
    }

    .button {
      cursor: pointer;

      &.active {
        color: $color-primary;
      }
    }
  }
}

.file-uploads {
  margin-top: 50px;
  color: $color-primary-text;

  .running-uploads {
    width: inherit;
    background-color: white;
    padding: 1em;
    margin-bottom: 5px;

    .uploads-text {
      font-weight: bold;
    }

    span {
      display: inline-block;
      width: 300px;
      max-width: 300px;
      overflow: hidden;
      height: 20px;
      line-height: 20px;
      text-overflow: ellipsis;
      position: relative;
      vertical-align: middle;
    }

    .progressbar-container {
      position: relative;
      display: inline-block;
      width: 300px;
      left: 0;
      margin-left: 10px;
      @include transform(translateX(0));
    }
  }

  .dropzone {
    width: inherit;
    text-align: center;
    color: $color-primary-contrast-text;
    background-color: $color-primary;
    cursor: pointer;
    font-weight: bold;

    a {
      padding: 2em;
      display: block;
    }

    &.active, &:hover {
      color: $color-primary-contrast-text;
      background-color: $color-dark;
    }
  }
}

.overlay {
  .overlay-wrapper {

    .overlay-content {

      .singlefile-edit, .multifile-file-edit {

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          max-height: 45vh;
        }

        > div {
          text-align: left;
        }

        .field-item {
          .field-label {
            display: inline-block;
            margin-bottom: 5px;
            padding-left: 30px;
          }

          .field-input {
            input {
              font-size: $font-size-standard;
              height: 30px;
              width: 310px;
              margin-left: 10px;
              box-sizing: border-box;
              border: 1px solid $color-subtle-grey;
              padding: 3px 8px;
            }
            margin-bottom: 15px;

            .langtag {
              width: 20px;
              float: left;
              text-align: center;
              cursor: pointer;
            }
          }
        }

        .cover-wrapper, .properties-wrapper, .multifile-wrapper {
          display: inline-block;
          margin-bottom: 40px;
          margin-left: 25px;
          vertical-align: top;
        }

        .cover-wrapper {
          width: 260px;
          text-align: center;
          margin-right: 40px;

          .open-file {
            display: block;
            margin-top: 5px;

            a {
              display: block;
              background-color: #F0F0F0;
              border: 1px solid $color-subtle-grey;
              padding: 1em;

              &:hover {
                background-color: #FFFFFF;
              }
            }
          }

          .no-permission-upload-file {
            position: relative;
            height: 100%;
          }

          .file-icon {
            width: 100%;
            display: block;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
          }

          span.empty-icon {
            font-size: 50px;
            .fa-plus {
              font-size: 40px;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              color: #FFFFFF;
              background-color: $color-success;
              bottom: -15px;
              z-index: 2;
              line-height: 55px;
              right: 0px;
              left: auto;
            }
          }
        }

        .no-permission-upload-file, .dropzone {
          border: 1px dashed $color-button-grey;
        }

        .no-permission-upload-file {
          border: 1px solid $color-subtle-grey;

          .note {
            text-align: center;
            position: absolute;
            bottom: 10px;
            width: 80%;
            left: 10%;
          }
        }

        .properties-wrapper {
          margin: 0;
          width: calc(100% - 360px)
        }

        .multifile-wrapper {
          box-sizing: border-box;
          width: 260px;

          .dropzone {
            box-sizing: border-box;
            cursor: pointer;
            height: 320px;
            text-align: center;
            margin: 0;
            background-color: transparent;
            padding: 10px;
          }

          .language-switcher {
            margin-top: 5px;
          }

          .convert-multilanguage-note {
            padding: 10px;

            h4 {
              margin: 20px 0;
              font-family: $font-main;
              font-weight: bold;
              font-size: 16px;
            }

            p {
              color: $color-text-medium-grey;
            }
          }
        }

      }

      .multifile-file-edit {
        display: inline-block;

        .field-item {
          .field-label {
            padding-left: 0;
          }

          .field-input {
            input {
              margin-left: 0;
            }
          }
        }

        .language-switcher {
          margin-bottom: 15px;
        }
      }

      .cover {
        height: 320px;
        position: relative;

        .dropzone {
          cursor: pointer;
          box-sizing: border-box;
          background-color: transparent;
          margin: 0;
          height: 100%;
          position: relative;

          .replace-note {
            padding: 0;
            display: block;
            position: absolute;
            width: 100%;
            bottom: 10px;
            text-align: center;
          }
        }
      }
    }
  }
}

.overlay .overlay-wrapper .overlay-content .attachment-overlay-wrapper { // overrule some overlay styles
  margin: 0;
  height: 100%;
  overflow: hidden;

  .folder-file-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    i {
      margin-right: 5px;
    }

    .folder-navigation {
      flex: 1;
      background-color: $color-overlay-background-even;
      color: $color-primary-contrast-text;
      height: 100%;

      .back {
        padding: 23px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid lighten($color-text-light-grey, 5);
        height: 35px;

        button {
          padding: 10px 8px;
          border-radius: 3px;
          color: $color-text-medium-grey;

          display: flex;
          align-items: center;

          &:hover {
            color: $color-primary-contrast-text;
            background-color: $color-primary;
          }
        }

        .folder-name {
          color: $color-primary-text;
          font-weight: bold;
          padding: 4px 8px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .folder-list-wrapper {
        overflow-y: auto;
        height: calc(100% - 35px);

        padding-top: 1em;
        padding-bottom: 1em;

        box-sizing: border-box;

        ul li {
          width: 100%;
          position: relative;
          box-sizing: border-box;
          
          button {
            padding: 5px 20px;
            display: block;
            width: 100%;
            height: 100%;
            color: $color-primary-text;

            &:hover {
              color: $color-hover-foreground;
              background-color: $color-hover-background;
  
              &:before {
                font-family: FontAwesome;
                content: "\f178";
                position: absolute;
                right: 10px;
              }
            }
          }
        }
      }
    }

    .file-list {
      flex: 2;
      padding: 0;
      height: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      border-left: 1px solid lighten($color-text-light-grey, 5);

      .real-file-list {
        flex: 1 1 auto;
      }

      .ReactVirtualized__Grid__innerScrollContainer {
        padding: 15px;
        margin-top:  15px;
      }

      .file-wrapper {
        width: 100%;
        padding: 3px 15px;
        box-sizing: border-box;
      }

      .file {
        padding: 8px;
        border-radius: 3px;
        background-color: $color-primary-contrast-text;
        color: $color-primary;

        svg {
          fill: transparent;
        }

        .overlay-table-row {
          box-sizing: border-box;
          display: flex !important;
          align-items: center;
          justify-content: space-between;
          padding: 5px;
          width: 100%;

          span {
            flex: 1 1 auto;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        &.is-linked {
          background-color: $color-primary;
          color: $color-primary-contrast-text;
        }

        button {
          color: inherit;

          &:hover {
            color: $color-primary-darker;
            svg {
              fill: $color-primary;
            }
          }
        }

        &:hover {
          background-color: $color-hover-background;
          color: $color-hover-foreground;

          .media-options {
            color: $color-primary;
          }
        }

        font-size: small;
        font-weight: bold;

        .media-options {
          color: transparent;
          font-weight: normal;

          button {
            color: inherit;
          }
        }
      }
    }
  }
}
