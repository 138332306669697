@import "helper";
@import "variables";

#overlay{
  .column-editor-footer {
    text-align: center;

    a.button {
      margin-top: 10px;
    }
  }

  #column-editor {
    .column-editor-item {
      padding: 0 30px;
      margin-bottom: 35px;

      h1 {
        margin-bottom: 25px;
      }

      .input {
        margin-top: 15px;
      }

      text {
        margin-bottom: 25px;
      }
    }

    .column-editor-item:last-of-type {
      margin-bottom: 70px;
    }
  }
}
