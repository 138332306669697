#profile-view.profile-view {
  background-color: $color-white;
  display: flex;
  justify-content: center;
  overflow: auto;

  .profile-view__inner {
    max-width: 900px;
    padding: 50px;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 250px auto;
    gap: 64px;
    box-sizing: border-box;
  }

  .profile-view__navigation {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: start;
    gap: 16px;
  }

  .profile-view__content {
    height: 100%;
    padding-bottom: 50px;
  }

  .profile-view__link {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    font-size: 1.2em;

    i {
      margin-right: 24px;
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.04);
      border-color: rgba(0, 0, 0, 0.87);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      border-color: rgba(0, 0, 0, 0.87);
    }
  }
}

.user-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $color-primary-contrast-text;
  background-color: $color-primary;
}

.profile-tab {
  height: 100%;
  display: flex;
  flex-flow: column;

  &__header {
    margin: 16px 0 56px 0;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 175%;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #9E9E9E;
  }

  &__divider {
    border-top: 1px solid #EEEEEE;
  }

  &__content {
    height: 100%;

    .profile-tab__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
    }

    .profile-tab__subtitle {
      margin-top: 30px;
      font-size: 12px;
      font-weight: 500;
      color: #9E9E9E;
      text-transform: uppercase;
    }
  }

  &__actions {
    margin-top: 32px;
    display: flex;
    justify-content: end;
    gap: 16px;
  }

  &__button {
    padding: 4px 16px;
    border-radius: 4px;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 150%;

    &--cancel {
      color: rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.6);
    }

    &--submit {
      color: $color-primary-contrast-text;
      background-color: $color-primary;
    }
  }

  &--personal {
    .profile-tab__content {
      display: flex;
      gap: 60px;
    }

    .profile-tab__info {
      display: flex;
      flex-flow: column;
      gap: 32px;
    }

    .profile-tab__section {
      display: flex;
      flex-flow: column;
      gap: 16px;
    }

    .profile-tab__icon {
      grid-column: icon;
      height: 80px;
      width: 80px;
      font-size: 2.5rem;
      font-weight: lighter;
    }

    .profile-tab__label {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  &--settings {
    .profile-tab__content {
      height: auto;
    }

    .profile-tab__section {
      display: grid;
      grid-template-areas:
        "label       toggle"
        "description toggle";
      gap: 0 60px;
      padding: 16px 0;
    }

    .profile-tab__label {
      grid-area: label;
      font-size: 1.4rem;
      line-height: 150%;
    }

    .profile-tab__description {
      grid-area: description;
      font-size: 1.2rem;
      color: #9E9E9E;
      line-height: 150%;
    }

    .profile-tab__toggle {
      grid-area: toggle;
    }
  }
}