$bubble-background: transparentize(black, 0.3);
@mixin tooltip-arrow {
  content: "";
  position: absolute;
  bottom: -15px;
  width: 0;
  height: 0;
  left: 22px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.tooltip {
  color: white;
  position: absolute;
  bottom: calc(100% + 15px);
  left: -20px;
  z-index: 9999;

  &:after {
    @include tooltip-arrow();
    border-top: 15px solid $bubble-background;
  }

  .tooltip__content {
    padding: 10px;
    border-radius: 5px;
    background-color: $bubble-background;
    z-index: 9999;
  }

  &--invert {
    top: calc(100% + 15px);

    &:after {
      @include tooltip-arrow();
      border-top: none;
      border-bottom: 15px solid $bubble-background;
      bottom: 35px;
    }
    .tooltip__content {
      padding: 10px;
      border-radius: 5px;
      background-color: $bubble-background;
      z-index: 9999;
    }
  }
}

.status-cell {
  .tooltip {
    inline-size: 200px;

    &__text {
      white-space: normal;
    }

    &--invert {
      bottom: none;
      top: calc(100% + 15px);
    }
  }
}
