$arrow-height: 50px;
.overlay .entity-view {
  $pseudo-header-height: 50px;

  .translation-arrow {
    position: fixed;
    left: -$arrow-height * 0.3 + 1px;
    top: 0;
    border-top: 0.50*$arrow-height solid transparent;
    border-bottom: 0.5*$arrow-height solid transparent;
    border-right: $arrow-height/10*3 solid $color-primary;
    z-index: 2;
  }

  .translation-view {
    position: absolute;
    left: -40vw;
    width: 40vw;
    top: 0;
    height: 100vh;
    background-color: $color-overlay-background;
    display: flex;
    flex-direction: column;
    transform: translate3d(0,0,0); // Stop OSX+Chrome from having strange depth-buffer issues

    .translation-item {
      padding: 15px;
      display: flex;
      align-items: flex-start;

      &>div {
        padding: 5px;
      }
    }

    .translation-select {
      background-color: darken($color-overlay-background-even, 10);
      border-bottom: 1px solid transparentize($color-text-medium-grey, 0.4);
    }

    .content-items {
      overflow: hidden;
      overflow-y: auto;
    }

    .content-box {
      overflow-y: hidden;
    }

    .item-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3px 8px;
      border-radius: 3px;
      width: 60px;
      border: 1px solid $color-primary-lighter;
      cursor: pointer;

      .langtag {
        text-transform: none;
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }

      &:hover {
        background-color: $color-hover-background;
        color: $color-hover-foreground;
      }

      &.main {
        background-color: $color-primary-lighter;
        color: $color-primary-contrast-text;
      }
    }

    .item-content {
      flex: 1;
    }

    .toggle-button {
      @include button-with-icon();
      width: auto;
      font-size: 7px;

      &:hover svg {
        fill: $color-text-medium-grey;
      }
    }

    .item.needs-translation {
      .label:before {
        position: absolute;
        left: 4px;
        content: "";
        width: 0;
        height: 0;
        border: 3px solid $color-needs-translation;
        border-radius: 3px;
      }
    }

    .pseudo-header {
      flex: 0 0 auto;
      display: grid;
      grid-template-columns: 50px 1fr auto;
      height: $pseudo-header-height;
      align-items: center;
      font-weight: bold;
      justify-content: space-between;
      background-color: $color-overlay-header;
      color: $color-primary-contrast-text;
      padding-right: 10px;

      // Cast a shadow inwards over the translation view
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        box-shadow: inset -10px 0 40px -10px $color-very-dark;
        z-index: 1;
        pointer-events: none;
      }

      .title {
        flex: 1;
        text-transform: uppercase;
      }

      .toggle-all-button {
        font-weight: normal;
        padding: 5px;
        border-radius: 3px;
        color: $color-primary;

        &:hover {
          background-color: $color-primary;
          color: $color-primary-contrast-text;
        }
      }

      .pseudo-header__close-button {
        @include button-with-icon();
        width: auto;
        padding: 8px;
        
        &:hover svg {
          fill: $color-text-medium-grey;
        }
      }
    }

    .empty-item {
      color: $color-text-medium-grey;
    }

    .single-value {
      width: 100%;
      height: 80%;
      margin-top: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
