.reset-table-view-button {
  display: inline-block;
  white-space: nowrap;

  margin-top: 10px;
  margin-right: 20px;

  @include header-button-look();
}

.reset-table-view-message {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.reset-table-view-link {
  width: fit-content;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  color: $color-primary;

  
  i {
    font-size: 12px;
  }
}