@import "variables";

@mixin full-screen() {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: white;
  opacity: 0.95;
}

#jump-spinner-wrapper {
  .jump-spinner {
    @include full-screen();

    .jump-spinner-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .jump-spinner-img {
      }

      .jump-spinner-title {
        padding: 0.5em;
        font-size: xx-large;
      }

      .jump-spinner-subtitle {
        min-height: 2em;
        font-size: large;
        color: $color-text-medium-grey;
      }
    }
  }
}

.search-overlay {
  @include full-screen();
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .heading {
    font-size: xx-large;
    padding: 0.5em;
  }
  .message {
    font-size: large;
    color: $color-text-medium-grey;
  }
}