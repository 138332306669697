.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 6px;

  &__icon {
    color: #9E9E9E;
    font-size: 1.4rem;
    font-weight: bold;
  }

  &__link:not(.active) {
    color: #9E9E9E;
  }

  &__link.active {
    pointer-events: none;
  }
}