.taxonomy-search {
  &.filter-bar {
    .taxonomy-search__results {
      top: calc(100% - 16px);
    }
  }
  position: relative;
  &__wrapper {
    position: relative;
    font-size: 18px;
  }
  &__input {
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    border: 1px solid transparent;

    &--disabled {
      // need to set important as some components target their search input very specific
      background-color: $color-disabled-grey !important;
      border-color: $color-disabled-grey !important;
    }
  }
  &__icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-text-medium-grey;
  }

  &__results {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: $color-white;
    max-height: 40vh;
    overflow: auto;
    box-shadow: $box-shadow-light;
    border-radius: 4px;
    z-index: 1;
  }

  &__result-item {
    &--placeholder {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      color: $color-text-medium-grey;
    }
    margin: 2px 0;
    &:hover {
      background-color: transparentize($color-text-medium-grey, 0.8);
      cursor: pointer;
    }
    &__wrapper {
      display: flex;
      padding: 8px 16px;
      color: $color-very-dark;
    }

    &__title {
      font-weight: bold;
    }

    &--leaf {
      border-left: 6px solid $color-primary;
      .taxonomy-search__result-item__wrapper {
        color: $color-primary;
        padding-left: 10px;
      }
    }

    &__path {
      display: flex;
      flex: 0 1 auto;
      min-width: 0;
      color: $color-text-medium-grey;

      &-step {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-style: italic;
        &:hover {
          min-width: fit-content;
        }

        &:last-child {
          margin-right: 8px;
        }
      }
      &-separator {
        margin: 0 4px;
      }
    }
  }
}
