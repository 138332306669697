.taxonomy-dashboard {
  h1 {
    font-size: 20px;
    font-weight: 500;
  }
  &__content-wrapper {
    padding-top: 55px;
    color: $color-very-dark;
  }

  &__header {
    background-color: $color-white;
    padding: 32px 56px;
    box-shadow: 0 1px 15px 1px $color-text-light-grey;
  }

  &__content {
    padding: 56px;
    padding-right: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    h1 {
      font-size: 18px;
    }
    height: 210px;
    width: 320px;
    background-color: $color-white;
    border-radius: 8px;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-right: 56px;
    margin-bottom: 56px;

    &__header {
      margin-bottom: 8px;
    }
    &__content {
      flex: 1;
      overflow: auto;
      color: $color-text-medium-grey;
      line-height: 19.5px;
    }
    &__footer {
      padding-top: 16px;
    }

    .button {
      @include button-look();
    }
  }

  &__content--empty {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 24px;

    .fancy-image {
      width: 33vw;
      min-width: 700px;
      height: auto;
      margin: 32px 0;
    }

    .title {
      font-size: 24px;
      font-weight: 400;
      margin: 24px 0;
    }

    .description {
      font-size: 18px;
      font-weight: 400;
      max-width: 33vw;
      text-align: center;
      color: $color-text-medium-grey;
    }

    .support-email-button {
      @include button-look();
      margin: 48px 0;
    }
  }
}
