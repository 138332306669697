.language-switcher {
  width: 80px;
}

.langtag-label {
  margin-left: 0.3em;
}

header {
  .language-switcher {
    margin-top: 10px;
    float: right;
  }
}
