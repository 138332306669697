@import "helper";
@import "variables";

#tableswitcher-wrapper {
  display: inline-block;

  margin-top: 10px;

  outline-style: none;

  .button {
    @include header-button-look();

    i {
      margin-right: 10px;
    }
  }

  &.active {
    .button {
      @include header-button-active();
    }
  }

  &.admin-mode {
    .button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }


  #tableswitcher-popup {
    @include header-panel-look();
    padding: 0;
    width: 600px;
    overflow: visible;
    height: 40vh;
    min-height: 350px;
    max-height: 400px;

    #tableswitcher-popup-internal-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    button {
      @include button-look();
      float: right;
    }

    .tableswitcher-label {
      color: $color-dark;
      font-weight: bold;
      font-size: 1em;
      height: 14px;
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }

  .tableswitcher-groups, .tableswitcher-tables {
    vertical-align: top;
    display: inline-block;
    box-sizing: border-box;
    padding: 10px;
    overflow: visible;
    width: 50%;
    height: 100%;
    position: relative;

    .tableswitcher-input-wrapper2 {
      overflow: hidden;

      .tableswitcher-input-wrapper {
        position: absolute;
        height: auto;
        width: 100%;
        left: 0;

        padding: 10px 0 10px 0;

        background-color: $color-background-very-light-grey;

        border: 1px solid $color-grey-dark;
        border-left: 0;
        border-right: 0;
      }

      i {
        position: absolute;
        right: 8%;
        top: 50%;
        margin-top: -0.5em;
      }

      .tableswitcher-input {
        font: inherit;

        box-sizing: border-box;

        position: relative;
        width: 90%;
        height: 30px;
        left: 5%;
        right: 5%;

        padding: 0 10px;
        margin: 0;

        cursor: pointer;

        outline: none;

        line-height: 30px;
        border: 1px solid $color-grey-dark;
        @include border-radius(3px);

        &:focus, &:active {
          border-color: $color-primary-lighter;
        }
      }

      .tableswitcher-input::-ms-clear {
        display: none;
      }
    }

    li {
      position: relative;
      height: auto;
      font-size: 1em;
      line-height: 1.5;
      padding: 3px 5px 3px 20px;
      border: 1px solid transparent;

      i {
        position: absolute;
        right: 0.3em;
        top: 50%;
        margin-top: -0.5em;
        opacity: 0.6;
      }
    }

    li.active {
      background-color: #EBEBEB;
    }

    li:focus, li:hover {
      cursor: pointer;
      background-color: #ECF7FF;
      outline: none;
    }
  }

  .tableswitcher-tables {
    border-left: 1px solid $color-grey-dark;

    .tableswitcher-tables-list {
      margin: 0;
      box-sizing: border-box;
      overflow-y: auto;
      position: absolute;
      top: 86px;
      bottom: 0;
      height: auto;
      width: 100%;
      left: 0;

      i {
        right: 20px;
      }
    }

    .tableswitcher-tables-search {
      height: 86px;
    }

    .separator {
      padding: 15px 20px;
      .no-results {
        color: $color-text-medium-grey;
        padding-bottom: 20px
      }
      .in-all-tables {
        border-top: 1px solid $color-text-light-grey;
        padding-top: 20px;
        font-weight: bold;
      }
    }
  }

  .tableswitcher-groups {
    background-color: $color-background-very-light-grey;

    .tableswitcher-groups-list {
      border-top: 1px solid $color-grey-dark;

      position: absolute;
      top: 34px;
      left: 0;

      height: calc(100% - 34px);
      width: 100%;

      overflow-y: auto;

      .nogroup {
        font-weight: bold;
      }
    }
  }
}
