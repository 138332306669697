@import "../variables";

.cell-stack {
  display: flex;
  flex-direction: column;

  .cell {
    background-color: lighten($color-background-very-light-grey, 1);
  }

  .repeat {
    justify-content: center;
    background-color: $color-background-very-light-grey;
    color: $color-text-medium-grey;
  }

  & > :first-child {
    border-top: 1px solid $color-text-light-grey;
  }
  & > :last-child {
    border-bottom: 1px solid $color-text-light-grey;
  }
}

.cell {
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0 9px;
  overflow: visible;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  border-right: 1px solid $color-border-grey;
  border-bottom: 1px solid $color-border-grey;
  outline: none;
  box-sizing: border-box;
  height: 45px;
  width: 100%;
  background-color: white;

  button {
    outline: none;
  }

  .more {
    top: 5px;
    display: inline-block;
    vertical-align: bottom;
  }

  .input {
    font: inherit;
  }

  .edit,
  .expand {
    width: 30px;
    height: 30px;
    line-height: 25px; // Suggested by Alex to center icon on his browser
    border-radius: 30px;
    vertical-align: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid $color-border-grey;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    font-weight: bold;
    color: $color-primary;
    @include box-shadow(0px 4px 10px 0px rgba(black, 0.35));
    cursor: pointer;

    &:hover {
      color: $color-primary-contrast-text;
      background-color: $color-primary-darker;
      border-color: $color-primary-darker;
    }
  }

  .cell-content.is-multiline {
    display: flex;
    align-items: center;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    i {
      margin-left: 5px;
      color: $color-text-medium-grey;
    }
  }
  .cell-content {
    overflow: hidden;
    height: $space-height-cell-content;
    line-height: $space-height-cell-content;
    display: block;
    position: relative;
    white-space: nowrap;
    width: 100%;

    .grey-out,
    .grey-out input {
      color: $color-text-medium-grey;
    }

    input,
    textarea {
      outline: none;
      padding: 0;
      border: 0;
      background-color: transparent;
      width: 100%;
      display: block;
      height: 150px;
    }

    input {
      line-height: $space-height-cell-content;
      height: $space-height-cell-content;
      margin: 0;
    }
  }

  &.cell-shorttext,
  &.cell-text,
  &.cell-richtext {
    .cell-content {
      white-space: pre; // let the user see white space issues in preview mode
    }
  }

  &.cell-boolean .cell-content {
    flex: 1;
  }

  &.cell-concat,
  &.cell-group {
    .cell-content {
      width: 100%;
    }
  }
  &.cell-status {
    background-color: #f9f9f9;
  }

  &.selected {
    border: 1px solid $color-primary;

    &.cell-link,
    &.cell-attachment {
      .cell-content {
        white-space: normal;
      }
    }

    &.cell-link,
    &.cell-attachment {
      &.cell-text {
        white-space: pre;
      }
    }
  }

  &.in-selected-row {
    background-color: $color-selected-row;
  }
  &.in-multi-selection {
    background-color: $color-selected-multi;
  }

  &.editing {
    //Important for datepicker
    &.cell-datetime,
    &.cell-date {
      .cell-content {
        overflow: visible;
      }
      i {
        line-height: $space-height-cell-content;
        margin-left: 5px;
        cursor: pointer;

        &:hover {
          color: $color-primary;
        }
      }

      &.editing {
        .cell-content {
          @include user-select(none);
        }
      }
    }

    &.cell-shorttext .cell-content {
      overflow: visible;
    }
  }

  &.holds-checkbox {
    vertical-align: middle;
    text-align: center;

    .checkbox {
      vertical-align: middle;
      margin: 0 auto;
      padding: 0;
      width: 16px;
      height: 16px;
      display: inline-block;
    }
  }

  &.cell-concat,
  &.cell-disabled {
    background-color: #f9f9f9;
    color: $color-text-light-grey;
    //Overwrite editing styling because concat cells are not editable
    &.editing {
      @include box-shadow(none);
    }
  }

  &.archived {
    background: $color-archived-row-bg;
    color: $color-archived-row-fg;

    &.in-selected-row {
      background: darken($color-archived-row-bg, 5%);
      .cell-content {
        background: darken($color-archived-row-bg, 5%);
      }
    }
  }
}

.cell.editing .row-display-label.below {
  display: none;
}
