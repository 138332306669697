@mixin taxonomy-interactive-element(
  $idle-color: $color-text-light-grey,
  $hover-color: $color-light-blue,
  $active-color: $color-primary
) {
  background-color: $color-white;
  border-radius: 4px;

  border-color: $idle-color;
  svg > * {
    fill: $idle-color;
  }

  &:hover {
    border-color: $hover-color;
    svg > * {
      fill: $hover-color;
    }
  }

  &--active,
  &:focus {
    border-color: $active-color;
    svg > * {
      fill: $active-color;
    }
  }
}

.tableaux-table--taxonomy {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .empty-taxonony-table {
    text-align: center;
    .skeleton__rows {
      margin-bottom: 56px;
      display: grid;
      grid-template-columns: var(--tree-item-height) 1fr;
      grid-gap: var(--tree-item-margin-y);
    }
    .skeleton__button,
    .skeleton__row {
      height: var(--tree-item-height);
      border-radius: 4px;
    }
    .skeleton__button {
      background-color: transparentize($color-text-medium-grey, 0.7);
    }
    .skeleton__row {
      background-color: transparentize($color-text-medium-grey, 0.9);
    }

    .create-row-button {
      padding: 40px 72px;
      border: 1px solid $color-primary-text;
      color: $color-primary-text;
      border-radius: 4px;

      &__text {
        margin-left: 8px;
        font-weight: 500;
      }

      &:hover {
        border-color: $color-light-blue;
        background-color: $color-light-blue;
        color: $color-primary;
        svg * {
          fill: $color-primary;
        }
      }
    }
  }

  .tree {
    padding: 24px 56px;
  }

  .table__subheader {
    color: $color-very-dark;
    background-color: $color-white;
    padding: 32px 56px;
    box-shadow: 0 1px 15px 1px $color-text-light-grey;
    z-index: 1;

    &-title {
      font-size: 20px;
      font-weight: 500;
    }

    .taxonomy-table__search {
      width: 900px;
      margin-top: 24px;

      .taxonomy-search__input {
        @include taxonomy-interactive-element();
        color: $color-primary-text;
        &--placeholder {
          font-style: italic;
        }
      }
    }
  }

  .taxonomy-table__tree {
    overflow-y: auto;
    flex: 1;
  }
  .tree-node {
    &__menu-button {
      flex: 1;
      height: 100%;
      background-color: $color-light-blue;
      border-radius: 4px;
      @include flex-centered();
      position: relative;
      color: $color-primary;
      & > * {
        fill: $color-primary;
      }

      &:hover {
        background-color: darken($color-hover-background, 10);
      }

      &--open,
      &--open:hover {
        background-color: $color-primary;
        color: $color-primary-contrast-text;
        & > * {
          fill: $color-primary-contrast-text;
        }
      }
    }

    &__menu-popup {
      position: absolute;
      left: calc(100% + 8px);
      top: 0;
      background-color: $color-white;
      box-shadow: $box-shadow-light;
      border-radius: 4px;
      z-index: 1;

      &--invert {
        top: auto;
        bottom: 0;
      }

      &__item-group {
        &:not(:last-of-type) {
          border-bottom: 1px solid $color-text-light-grey;
        }
      }

      &__item {
        display: grid;
        padding: 12px 16px;
        padding-right: 32px;
        grid-template-columns: var(--tree-item-height) 1fr;
        color: $color-very-dark;

        & > svg > * {
          fill: $color-very-dark;
        }

        .item__icon {
          @include flex-centered();
          font-size: 16px;

          .flip-lr {
            transform: scaleX(-1);
          }

          .rotate-90 {
            rotate: 90deg;
          }
        }

        .item-title {
          white-space: nowrap;
        }

        &:hover {
          background-color: transparentize($color-text-light-grey, 0.8);
        }
      }
    }
  }
}
