.support {
  width: 100%;
  position: relative;
  background-color: $color-primary-contrast-text;
  height: 260px;
  overflow: visible;
  border-bottom: 2px solid $color-border-grey;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0;

    .heading {
      color: $color-primary-text;
      font-size: x-large;
      font-weight: bold;
    }
  }

  .tiles {
    display: flex;
    justify-content: center;

    > div {
      border-radius: 5px;
    }

    .contact-info, .feedback {
      width: 290px;
      height: 200px;
      padding: 20px;
      color: $color-primary-contrast-text;
    }

    .separator {
      width: 60px;
    }

    .heading {
      font-size: large;
      font-weight: bold;
    }

    .contact-info {
      background-color: #2f95db;

      display: flex;
      flex-direction: column;
      justify-content: space-between;


      .contact-data {
        font-size: small;
      }

      a.details:hover {
        color: $color-text-light-grey;
      }

      .details {
        display: flex;
        align-items: center;

        i.fa {
          font-size: 0.8em;
          width: 11px;
          text-align: center;
          padding-right: 3px;
        }

        &.title {
          font-weight: bold;
        }
      }
    }

    .feedback {
      background-color: $color-overlay-header;
      position: relative;

      .input {
        width: 100%;
        height: 60%;
        border-radius: 3px;
        border: none;
        padding: 10px 15px;
        box-sizing: border-box;
        margin-top: 20px;
        resize: none;
      }

      .submit-button {
        @include button-look();
        margin-top: 15px;
        position: absolute;
        right: 20px;
        bottom: 16px;
      }
    }
  }
}