/*
$table-background-color: #fafafa;
$head-cell-background-color: #ffffff;
$selected-head-cell-background-color: #ffa088;

$link-background-color : #fff;
$link-foreground-color : #88f;
$link-add-color : #8f8;

$status-text-color: #000000;
$status-border-color: #000000;
$status-background-color: #fefefe;

$status-error-text-color: #333;
$status-error-border-color: #ff8080;
$status-error-background-color: #ffa088;*/

// Colors ----------------------------------- //
$color-body-background: #efefef;
$color-background-very-light-grey: #f8f8f8;
$color-primary: #3296dc;
$color-primary-hover: #96d9ff;
$color-primary-lighter: #91c8ff;
$color-primary-darker: darken($color-primary, 10);
$color-subtle-grey: #dfdfdf;
$color-text-light-grey: #c4c4c4;
$color-dark: #555555;
$color-text-medium-grey: #999999;
$color-very-dark: #333333;
$color-button-grey: #a0a0a0;
$color-border-grey: #efefef;
$color-primary-contrast-text: #ffffff;
$color-primary-text: #333333;
$color-link-color: #e4eff7;
$color-grey-dark: #dedede;
$color-light-link-color: #e7f1f8;
$color-darker-link-color: #c5d9e8;
$color-text-link-color: #7c94a6;
$color-selected-row: var(--color-selected-row);
$color-selected-multi: #e0f2ff;
$color-select-list-focus: #e8e8e8;
$color-select-list-selected: #e8f6ff;
$color-success: #5ec786;
$color-green: $color-success;
$color-red: #d86357;
$color-light-blue: #bde4ff;
$color-very-light-blue: #e7f1f8;
$dark-blue-grey-background: #3c4246;
$color-background-disabled: #fafafa;
$color-foreground-disabled: $color-text-medium-grey;
$color-needs-translation: var(--color-needs-translation);
$color-needs-translation-dark: color-mix(
  in srgb,
  var(--color-needs-translation),
  black 5%
);
$color-fully-translated: lighten($color-green, 30);
$color-hover-background: $color-light-link-color;
$color-hover-foreground: $color-primary;
$color-important: var(--color-important);
$color-checkme: var(--color-doublecheck);
$color-postpone: var(--color-postpone);
$color-comments: #2f95db;
$color-disabled-grey: #f0f0f0;

$color-black: black;
$color-white: white;

$color-overlay-header: #3c4246;
$color-overlay-background: #e9e9e9;
$color-overlay-background-even: #dfdfdf;
$color-overlay-footer: #fff;
$color-overlay-question: #fb4;
$color-overlay-success: #6ec;
$color-overlay-warning: #f55;

$color-hover-dark: #4a4f53;
$color-revision-added: #d1f2e7;
$color-revision-link-added: #66d6b0;
$color-revision-deleted: #dfdfdf;
$color-revision-filter-background: #464b4f;
$color-archived-bg: #999999;
$color-archived-fg: $color-white;
$color-archived-row-bg: #e5e5e5;
$color-archived-row-fg: #999999;

$color-header-gradient-start: #2f95db;
$color-header-gradient-end: #15e2c3;

// Fonts ----------------------------------- //
$font-main: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
$font-sec: $font-main;

// Font sizes ----------------------------------- //
$font-size-base: 10px;
$font-size-standard: 1.3rem;
$line-height-standard: 14px;

// Spaces ----------------------------------- //
$space-padding-header: 5%;
$space-height-table-switcher: 60px;
$space-height-header: 55px;
$space-height-header-buttons: 30px;
$space-standard-cell-width: 300px;
$space-height-cell: 45px;
$space-height-table-header: 40px;
$space-border-size-table-header: 3px;
$space-inner-height-table-header: $space-height-table-header -
  $space-border-size-table-header;
$space-height-cell-content: 25px;
$space-height-link-delete-button: 23px;

// Button look helpers ---------------------- //
@mixin rounded-lower-borders {
  border-radius: 0 0 3px 3px;
}

@mixin rounded-upper-borders {
  border-radius: 3px 3px 0 0;
}

@mixin button-with-icon {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

@mixin status-dot(
  $dot-color,
  $background-color,
  $diameter: 6px,
  $yoffset: -2px
) {
  &:before {
    position: absolute;
    content: "";
    width: $diameter;
    height: $diameter;
    border-radius: $diameter;
    top: -$diameter / 2 + $yoffset;
    padding: 1px;
    border: 1px solid $background-color;
    background: $dot-color;
    box-shadow: none;
  }

  &.active:before {
    box-shadow: 0 0 0 1px $dot-color;
  }

  &.inactive:before {
    box-shadow: none;
    background: color-mix(in srgb, $dot-color, black 10%);
  }
}

@mixin status-dot-hovered(
  $dot-color,
  $background-color,
  $diameter: 6px,
  $yoffset: -2px
) {
  &:before,
  &.inactive:before {
    position: absolute;
    content: "";
    width: $diameter;
    height: $diameter;
    border-radius: $diameter;
    top: -$diameter / 2 + $yoffset;
    padding: 1px;
    border: 1px solid $background-color;
    background: $dot-color;
    box-shadow: 0 0 0 1px $dot-color;
  }

  &.active:before {
    box-shadow: none;
    background: color-mix(in srgb, $dot-color, black 10%);
  }
}

@mixin cell-flag-dot($dot-color, $size: 5px) {
  height: $size;
  margin-top: 2px;
  position: relative;
  width: 100%;
  &:before {
    content: "";
    width: $size;
    height: $size;
    border-radius: $size;
    background: $dot-color;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@mixin clickable-svg-icon(
  $color-unhovered: $color-primary-contrast-text,
  $color-hovered: $color-text-medium-grey
) {
  .svg-icon-content * {
    fill: $color-unhovered;
  }

  &:hover .svg-icon-content * {
    fill: $color-hovered;
  }
}

.hfill {
  flex: 1;
}

// stolen from MUI elevation-6
$box-shadow-light: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

:root {
  --color-important: #ed475e;
  --color-postpone: #999999;
  --color-doublecheck: #66d6b0;
  --color-needs-translation: #fb9429;
  --color-selected-row: #f3faff;
}
