.linked-items {
  .link-list {
    width: 100%;
    box-sizing: border-box;

    & > div {
      margin: 3px 0;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 25px;
    }

    &.empty-info {
      padding: 20px 40px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color-text-medium-grey;

      i {
        font-size: larger;
        margin-right: 1em;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .draggable {
    display: flex;
    align-items: center;

    .drag-handle {
      cursor: ns-resize;
      margin-right: 5px;

      svg {
        fill: transparent;
      }
    }

    &:hover .drag-handle svg {
      fill: $color-text-medium-grey;
    }

    &.is-hovered {
      opacity: 0.2;
    }
  }

  div:nth-child(2) {
    flex: 1;
  }
}

.items-title {
  display: flex;
  flex-wrap: wrap;

  span {
    padding-top: 20px;
    padding-left: 50px;

    // fixed height prevents LinkOverlay from infinitely jumping between different line breaks when linking to table with
    // titles containing long words (eg. "Sicherheitsbekleidungsvarianten")
    height: 32px;
    font-size: large;
  }

  .link-status-count {
    width: 100%;
    padding-top: 0;

    span {
      padding: 0;
    }

    .text {
      font-size: smaller;
      color: $color-text-medium-grey;
    }

    .number {
      padding: 0 5px;
      font-size: smaller;
      font-weight: bold;
    }
  }

  .table-link {
    color: $color-primary;
    font: inherit;
    padding: 0;
  }
}

.list-item {
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  height: 37px;
  border-radius: 3px;
  background-color: transparent;
  color: $color-black;
  overflow: hidden;
  position: relative;
  align-items: center;
  box-sizing: border-box;

  .link-item-button.archived {
    background: $color-archived-bg;
    color: $color-archived-fg;

    svg {
      fill: $color-archived-fg;
    }
    &:hover {
      background: transparentize($color: $color-archived-bg, $amount: 0.5);
    }
  }

  .link-label {
    height: $line-height-standard;
    overflow: hidden;
  }

  &.isLinked {
    background-color: transparent;
    color: $color-primary-contrast-text;
  }

  .left {
    flex-grow: 1;
    padding: 0 25px;
    background-color: $color-white;
    color: black;
    display: flex;
    align-items: center;
    height: 100%;

    a {
      display: block;
      flex: 90;
      height: $line-height-standard;
      overflow: hidden;
    }

    &.linked {
      background-color: $color-primary;
      color: $color-primary-contrast-text;
    }
    &.archived {
      background: $color-archived-bg;
      color: $color-archived-fg;
      ::before {
        content: "\f187";
        font-family: FontAwesome;
        padding-right: 8px;
      }
    }
  }

  .roundCorners {
    border-radius: 3px 0 0 3px;
  }

  .linkButton {
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100%;
    background-color: $color-light-blue;
    color: $color-primary;
    margin-right: 5px;

    &:hover {
      background-color: $color-very-light-blue;
    }

    .a {
      fill: $color-primary;
    }

    .svg-icon {
      cursor: pointer;
      width: 1em;
      height: 1em;
    }
  }

  .linkButton--disabled {
    cursor: not-allowed;
    opacity: 0.3;

    &:hover {
      background-color: $color-light-blue;
    }

    .svg-icon {
      cursor: not-allowed;
    }
  }
}
