@import "variables";

.filter-wrapper {
  display: flex;

  margin-top: 10px;
  margin-right: 20px;

  outline-style: none;

  &.active {
    .filter-popup-button {
      @include header-button-active();
    }
  }

  &.has-filter {
    .filter-popup-button {
      @include header-button-active();
      background-color: $color-primary;
      color: $color-primary-contrast-text;
    }
  }

  .filter-popup {
    @include header-panel-look();
    width: 630px;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 15px;
    max-height: calc(100vh - 80px);

    .column-filters,
    .annotation-filters {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 30px;
    }
    .column-filters__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: auto;
      max-height: 13em;
    }

    .annotation-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 6px;
      cursor: pointer;
      &:hover {
        background: $color-hover-background;
      }
    }

    .filter-settings {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $color-border-grey;
    }

    .annotation-badge__area {
      display: flex;
      gap: 50px;
      &-header {
        padding-left: 6px;
        padding-top: 9px;
      }
    }
    .annotation-filter__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .annotation-filter__badges {
      flex: 1;
      display: flex;
      justify-content: end;
      gap: 9px;
      padding-top: 6px;
      flex-wrap: wrap;
    }

    .button {
      display: flex;
      align-items: center;
      gap: 6px;
      border-radius: 3px;
      white-space: nowrap;
      &:hover {
        background: $color-hover-background;
      }
    }

    .button--add-filter {
      width: min-content;
      white-space: nowrap;
      padding: 6px 12px;
    }

    .button--remove-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2em;
      border-radius: 3px;
      color: $color-text-medium-grey;
      padding: 3px 9px;
    }

    .button--open-save-overlay {
      color: $color-primary;
      padding: 3px 6px;
    }

    .button--reset-sorting {
      padding: 3px 9px;
      color: $color-text-medium-grey;
      font-size: 1.2em;
    }

    .sorting-area {
      padding: 12px;
      &__content {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 12px;
        .Select:first-of-type {
          margin-right: 18px;
        }
        .Select:last-of-type {
          margin-left: 18px;
        }
      }
    }

    .filter-row {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr 1fr auto;
    }

    .filter-options {
      position: relative;
      width: 100%;
    }

    .filter-mode-wrapper {
      position: relative;

      input {
        height: 28px;
      }
    }

    .stored-filters-area {
      background: $color-border-grey;
      padding: 18px;
      .stored-filter {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 9px;
        align-items: center;
        height: 2em;
      }

      .button--set-stored-filter {
        color: $color-primary;
      }
      .button--delete-stored-filter {
        color: $color-text-medium-grey;
      }
      .stored-filters {
        max-height: 13em;
        overflow-y: auto;
      }
    }

    .context-menu {
      position: absolute;
      right: 0;
      top: 100%;
      width: 220px;
      color: black;
      border: 1px solid lightgrey;
      border-top: none;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      @include box-shadow(0px 0px 50px rgba(black, 0.2));

      background-color: $color-primary-contrast-text;
      padding: 0;

      .filter-mode-popup .item,
      .item a {
        &.active {
          color: $color-primary-contrast-text;
          background-color: $color-primary;
        }

        &:hover {
          color: $color-hover-foreground;
          background-color: $color-hover-background;
        }
      }
    }
  }

  .filter-popup-button {
    @include header-button-look();
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1.3rem; // ??? why?
    background-color: transparent;
    margin-left: 20px;

    i {
      margin-right: 10px;
    }
  }

  .button__toggle-archived-rows {
    i {
      margin: 0;
    }
  }

  .description-row {
    .info {
      color: $color-text-light-grey;
      padding: 20px 0;
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 1.5em;
      display: flex;
      justify-content: flex-end;

      .text {
        width: 50%;
        float: left;
        flex: 1;
      }

      button {
        margin: 0 10px;
        @include button-look();
        float: right;
      }

      .filter-popup__persisted-filter-title {
        padding: 0 5px;
        border-radius: 3px;
        border: 1px solid $color-text-light-grey;
      }
    }
  }
}

.filter-popup__button {
  @include header-button-look();
  // position: relative;

  &.filter-popup-button--open {
    background-color: $color-primary-text;
    color: $color-primary-contrast-text;
    border-color: transparent;
  }
}

.filter-preset-list {
  padding: 0;
  margin-top: 8px;
  max-height: 200px;
  overflow-y: auto;
}

.filter-preset-list__item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  background-color: $color-primary-contrast-text;
  padding: 8px 20px;
  color: $color-primary-text;

  &:hover {
    background-color: $color-hover-background;
  }
}

.filter-preset-item__button {
  color: $color-text-medium-grey;
  border-radius: 3px;
  padding: 0 5px;
  border: none;
  background-color: transparent;

  &:hover {
    color: $color-primary;
    cursor: pointer;
  }
}

.filter-popup__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}

.filter-popup__heading {
  font-weight: bold;
}

.filter-popup__content-section {
  padding: 15px;

  &.filter-popup-sorting-section {
    background-color: $color-background-very-light-grey;
  }
}

.filter-wrapper .filter-popup {
  .filter-popup__save-link-button,
  .filter-popup__toggle-list-button {
    background-color: transparent;
    border: none;
    color: $color-text-light-grey;
    text-decoration: underline;
    box-shadow: none !important;

    .fa {
      margin-right: 5px;
    }

    &:hover {
      background-color: transparent;
      color: $color-primary;
    }
  }

  .filter-popup__save-link-button {
    padding: 0;
  }

  .filter-popup__toggle-list-button {
    font-weight: bold;
    color: $color-text-medium-grey;
    font-size: 1.3rem;
    text-decoration: none;
    margin-left: 7px;

    &:hover {
      color: $color-primary;
    }
  }
}

.filter-popup__preset-list {
  background-color: $color-background-very-light-grey;
  margin: 0 -15px -15px -15px;
  padding: 15px;
  padding-bottom: 20px;
}

.save-template-popup {
  position: absolute;
  background-color: white;
  z-index: 2;
  box-shadow: 0 0 150px 10px $color-text-medium-grey;
  padding: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__header {
    color: $color-primary-text;
    font-weight: bold;

    margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    gap: 20px;
  }

  .button {
    @include button-look();
  }
}

.save-template__name-input {
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 15px;
  border: 1px solid $color-text-light-grey;
  border-radius: 3px;
}

.filter-input {
  border-radius: 4px;
  border: 1px solid transparentize($color-text-light-grey, 0.4);
  outline: none;
  padding: 0 8px;
  &:focus {
    outline: none;
  }
  box-shadow: none;
}
.filter-input__clear-button {
  background: transparentize(white, 0.3);
  position: absolute;
  top: 1px;
  right: 13px;
  border: none;
  padding: 4px 5px;
  z-index: 2;

  .svg-icon {
    fill: $color-text-medium-grey;
  }

  &:hover .svg-icon {
    fill: $color-primary;
  }
}
