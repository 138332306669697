:root {
  --tree-animation-duration: 300ms;
  --tree-item-height: 40px;
  --tree-item-margin-y: 8px;
  --tree-level-indent: 32px;
  --tree-half-indent: calc(var(--tree-level-indent) / 2);
  --tree-action-button-width: calc(var(--tree-item-height) + 8px);
  --tree-item-half-height: calc(var(--tree-item-height) / 2);
}

$tree-action-button-width: var(--tree-action-button-width);
$tree-half-indent: var(--tree-half-indent);
$tree-item-half-height: var(--tree-item-half-height);
$tree-line: 1px solid #ccc;

.tree {
  padding: 24px;
  max-width: 900px;

  .tree-node__item-button {
    display: grid;
    grid-template-columns: $tree-half-indent $tree-half-indent 1fr;
    &--with-action-button {
      grid-template-columns: $tree-half-indent $tree-half-indent $tree-action-button-width 1fr;
    }
  }
  .tree-node--root > .tree-node__item-button {
    display: grid;
    grid-template-columns: 1fr;
    &--with-action-button {
      grid-template-columns: $tree-action-button-width 1fr;
    }
  }
  .subtree {
    display: grid;
    grid-template-columns: $tree-half-indent $tree-half-indent 1fr;

    &__border-left {
      transition: border-color var(--tree-animation-duration) ease-out;
      border-right: $tree-line;
      border-color: transparent;
    }
  }
  .tree-node--root > .subtree {
    grid-template-columns: 1fr;
  }
  .tree-node {
    &__border-left {
      height: calc(100% + 2 * var(--tree-item-margin-y));
      border-right: $tree-line;
    }
    &__border-right {
      height: $tree-item-half-height;
      margin-bottom: $tree-item-half-height;
      border-bottom: $tree-line;
    }
  }
  .tree-node:last-child > .tree-node__item-button > .tree-node__border-left {
    height: $tree-item-half-height;
    margin-bottom: $tree-item-half-height;
  }

  .tree-node:last-child:first-child
    > .tree-node__item-button
    > .tree-node__border-left {
    height: calc(50% + var(--tree-item-margin-y) - 1px);
    margin-bottom: calc(
      var(--tree-item-height) / 2 + var(--tree-item-margin-y)
    );
  }
  .subtree--fill-line
    > .subtree__items
    > .tree-node
    > .subtree
    > .subtree__border-left {
    border-right: $tree-line;
  }

  .tree-node {
    .hfill {
      flex: 1;
    }
    &__item-button {
      height: var(--tree-item-height);
      cursor: pointer;
      align-items: center;
      margin-bottom: 8px;
    }
    &__item-action-button {
      height: var(--tree-item-height);
      width: var(--tree-item-height);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }

    &__title {
      flex: 1;
      display: flex;
      padding: 12px 24px;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color var(--tree-animation-duration) ease-in-out,
        color var(--tree-animation-duration) ease-in-out;
      min-height: 13px;
    }

    &__child-count {
      margin-left: 8px;
    }

    &--on-path,
    &--expanded {
      .tree-node__title {
        background-color: $color-primary;
        color: $color-primary-contrast-text;
      }
    }

    &--default {
      .tree-node__title {
        background-color: $color-white;
        color: $color-dark;
      }
    }

    &--leaf {
      .tree-node__title {
        color: $color-primary;
        border-left: 8px solid $color-primary;
      }
    }
  }

  .subtree {
    padding: 0;
    margin: 0;
    transition: all var(--tree-animation-duration) ease-in;
    height: 0;
    transform-origin: top;
    transform: scaleY(0);

    &--show {
      transform: scaleY(1);
    }
    &--entering {
      transform: scaleY(1);
    }
    &--leaving {
      transform: scaleY(0);
      height: 0;
    }
  }
}
