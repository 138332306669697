header {
  .Tableaux-Spinner {
    width: 50px;
    height: 100%;
    position: relative;
    float: right;
  }
}

.Tableaux-Spinner {
  .spinner {

    &.spinner-enter {
      opacity: 0;

      &.spinner-enter-active {
        opacity: 1;
        @include transition(opacity 750ms ease-in);
      }
    }

    &.spinner-exit.spinner-exit-active {
      opacity: 0;
      @include transition(opacity 300ms ease-in);
    }
  }
}




