.meta-cell {
  width: 80px !important;
  background-color: white;
  height: 45px;
  box-sizing: border-box;
  border-bottom: 1px solid $color-border-grey;
  border-right: 1px solid $color-border-grey;

  .cell-content {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 7px;
    justify-content: flex-end;
  }

  &:hover {
    cursor: pointer;
  }

  .row-number {
    color: $color-text-light-grey;
    font-size: 12px;
  }

  .row-expand {
    display: none;
  }

  &:hover {
    .row-number {
      display: none;
    }

    .row-expand {
      display: block;
    }
  }

  .meta-cell-icon {
    color: $color-button-grey;
    flex: 1;
    margin-left: 3px;
  }

  .delete-row {
    padding: 0;
    flex: 1;

    .button {
      width: 35px;
      height: 35px;

      font-size: 1em;

      border-radius: 35px;

      display: inline-block;
      text-align: center;

      background-color: white;
      border: 1px solid $color-border-grey;

      &:hover {
        color: $color-primary-contrast-text;
        background-color: $color-primary;
        border-color: $color-primary;
      }
    }
  }

  &.row-expanded {
    background-color: $color-background-very-light-grey;
    .cell-content {
      overflow: visible;

      .langtag {
        text-align: center;
        display: inline-block;
        line-height: 1;
      }

      .langtag span {
        display: block;
      }
    }
  }

  &.in-selected-row {
    background-color: $color-selected-row;
  }

  &.archived {
    background: $color-archived-row-bg;
    color: $color-archived-row-fg;
    &.in-selected-row {
      background: darken($color-archived-row-bg, 5%);
    }
  }
}
