$padding-x: 50px;
$padding-y: 24px;
$button-margin: 16px;

.overlay {
  .overlay-content {
    .overlay-subheader {
      background-color: white;
      margin: 0;
      padding: $padding-y $padding-x;
      border-bottom: 4px solid $color-text-light-grey;
      margin: 0;
      &__title {
        font-size: 20px;
        font-weight: 500;
        padding-bottom: 16px;
      }

      &__description {
        color: $color-text-medium-grey;
      }

      &__buttons {
        display: flex;
        padding-top: $button-margin;

        .button {
          @include button-look();
        }

        &--left {
          .button {
            margin-right: $button-margin;
          }
        }

        &--center {
          .button {
            margin: 0 $button-margin;
          }
        }

        &--right {
          .button {
            margin-left: $button-margin;
          }
        }
      }

      .cardinality-info {
        padding-bottom: 8px;
        &__text {
          color: $color-text-medium-grey;
        }
        &__count {
          color: $color-primary-text;
          font-weight: bold;
          margin: 0 4px;
        }
      }
    }
  }

  .select-link-target-overlay {
    .overlay-subheader {
      &__title {
        margin-bottom: 0;
      }
      .link-display-value {
        background-color: $color-primary;
        color: $color-primary-contrast-text;
      }
      .selected-link {
        margin-top: 16px;
      }
    }
    .overlay-content {
      display: flex;
      flex-direction: column;

      .select-link-target {
        &--has-link.overlay-subheader {
          background-color: transparent;
          border-bottom: none;
        }
        &.overlay-main-content {
          height: 100%;
          position: relative;
          margin: 0;
          padding-top: $padding-y;
        }
        .list-item {
          display: flex;
        }

        .items-virtualized-list {
          padding: 0 $padding-x;
          flex: 1;
        }

        .row-creator {
          position: absolute;
          z-index: 1;

          transition: bottom 0.3s ease-in, padding 0.3s ease-in;

          left: 50%;
          transform: translateX(-50%);
          bottom: $padding-y;
          background-color: $color-white;
          padding: 8px;
          display: flex;
          align-items: center;
          border: 2px solid $color-light-blue;
          border-radius: 5px;
          cursor: pointer;

          &--no-rows-available {
            bottom: 50%;
            padding: 25px;
            transform: translateX(-50%, 50%);
          }
        }
      }
    }
  }
}
