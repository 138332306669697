.completion-list {
  z-index: 2;
  position: absolute;
  @include grud-shadow();
  width: calc(100% + 20px);
  left: -10px;

  // force the background to be full width and undecorated
  .ReactVirtualized__Grid__innerScrollContainer {
    width: 100% !important;
    background: initial !important;
  }

  background-color: white;

  .completion-item-wrapper {
    height: 30px;

    .completion-item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: $color-primary-text;
      padding: 0 10px;

      &.selected {
        background-color: $color-select-list-selected;
      }
    }
  }
}
.selectable-shorttext_text-limits {
  line-height:3px;
  font-size: 8px;
  display: flex;
  justify-content: space-between;
}
.selectable-shorttext_error {
  color: red;
}

.selectable-shorttext_max-length {
  margin-right: 15px;
}
